import { drawerOpenWidth } from "./Drawer.jsx";
import {
  Box,
  LinearProgress,
  Toolbar,
  AppBar as MuiAppBar,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import TranslationSwitcher from "./TranslationSwitcher.jsx";
import NotificationsBell from "./NotificationsPopUp/NotificationBell.jsx";
import { useNavigation } from "react-router-dom";
import { LocationSelector } from "./LocationSelector.jsx";

const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  transition: theme.transitions.create(["width", "left"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        left: drawerOpenWidth,
        width: `calc(100% - ${drawerOpenWidth}px)`,
        transition: theme.transitions.create(["width", "left"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        left: 56,
        width: `calc(100% - ${56}px)`,
        transition: theme.transitions.create(["width", "left"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const SHOW_LOADING_AFTER = 200;
const SHOW_LOADING_AT_LEAST = 1000;

function time() {
  return new Date().getTime();
}

/**
 * This hook allows to show loading indicator whithout glitches when requested
 * - if loading time is fast - no need to show the indicator
 * - if we show the indicator, we shouldn't immediately hide it as it would look like glitch
 *
 * So this hook shows the indicator if loading takes longer than `SHOW_LOADING_AFTER` ms
 * for at least `SHOW_LOADING_AT_LEAST` ms.
 *
 * @param {boolean} show Loading requested?
 *
 * @returns {boolean} Loading should be shown
 */
function useLoading(show) {
  const [showingSince, setShowingSince] = React.useState(0);
  React.useEffect(() => {
    if (show) {
      const timeoutId = setTimeout(() => {
        setShowingSince(time());
      }, SHOW_LOADING_AFTER);

      return function () {
        clearTimeout(timeoutId);
      };
    }

    const currentTime = time();
    const hideIn = Math.max(
      0,
      showingSince + SHOW_LOADING_AT_LEAST - currentTime,
    );
    const timeoutId = setTimeout(() => {
      setShowingSince(0);
    }, hideIn);

    return function () {
      clearTimeout(timeoutId);
    };
  }, [show]);

  return showingSince !== 0;
}

function Loading() {
  const navigation = useNavigation();
  const showLoading = useLoading(navigation.state !== "idle");

  if (!showLoading) {
    return null;
  }

  return (
    <Box>
      <LinearProgress />
    </Box>
  );
}

export const AppBar = React.memo(function AppBar({ open }) {
  return (
    <CustomAppBar
      position="fixed"
      open={open}
      color="primary"
      sx={(theme) => ({ backgroundColor: theme.palette.primary["50"] })}
    >
      <Toolbar sx={{ justifyContent: "end" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <LocationSelector />
          <NotificationsBell />
          <TranslationSwitcher />
        </Box>
      </Toolbar>
      <Loading />
    </CustomAppBar>
  );
});
