import { parseDateTime } from "shared/src/date.mjs";

/**
 * @typedef {Date | string | null} DateParam
 */

/**
 * @param {DateParam} date
 *
 * @returns {string | null}
 */
export function dateInput(date) {
  if (date == null) {
    return null;
  }

  date = parseDateTime(date);

  return new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
}

/**
 * @param {DateParam} date
 *
 * @returns {string | null}
 */
export function dateTimeInput(date) {
  if (date == null) {
    return null;
  }

  date = parseDateTime(date);

  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -8);
}

/**
 * @param {DateParam} date
 *
 * @returns {string | null}
 */
export function longDateTime(date) {
  if (date == null) {
    return null;
  }

  date = parseDateTime(date);

  return new Intl.DateTimeFormat(undefined, {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(date);
}

/**
 * @param {DateParam} date
 *
 * @returns {string | null}
 */
export function date(date) {
  if (date == null) {
    return null;
  }

  date = parseDateTime(date);

  return date.toLocaleDateString();
}

/**
 * @param {DateParam} date
 *
 * @returns {string | null}
 */
export function time(date) {
  if (date == null) {
    return null;
  }

  date = parseDateTime(date);

  return new Intl.DateTimeFormat(undefined, { timeStyle: "short" }).format(
    date,
  );
}

/**
 * @param {DateParam} date
 *
 * @returns {string | null}
 */
export function time24(date) {
  if (date == null) {
    return null;
  }

  date = parseDateTime(date);

  return (
    String(date.getHours()).padStart(2, "0") +
    ":" +
    String(date.getMinutes()).padStart(2, "0")
  );
}

export function minutesToDuration(minutes) {
  if (Math.abs(minutes) < 100) {
    return `${minutes}m`;
  }

  const h = Math.trunc(minutes / 60);
  const m = Math.abs(minutes % 60);

  return `${h}h ${m}m`;
}

/**
 * @param {number} ms
 *
 * @returns {string}
 */
export function msToTime(ms) {
  const minutesTotal = Math.abs(Math.floor(ms / 1000 / 60));
  const hours = Math.floor(minutesTotal / 60);
  const minutes = Math.floor(minutesTotal % 60);

  const sign = ms < 0 ? "-" : "";

  return `${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
}

/**
 * @param {{first_name?: string, last_name?: string}} [obj]
 *
 * @returns {string}
 */
export function fullName(obj) {
  const parts = [obj?.last_name, obj?.first_name].filter((p) => p != null);

  return parts.join(" ");
}
