import React from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import * as api from "../services/api";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import * as f from "../utils/formatter";
import { Form, useLoaderData, useNavigation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { workingDays, workingHoursInDay } from "shared/src/calendar.mjs";
import AutocompleteSelect from "../components/AutocompleteSelect";
import CloseIcon from "@mui/icons-material/Close";
import ButtonLoader from "../components/ButtonLoader";
import { FormErrorList, useRouterFormAction } from "../form";
import { updateInIfExists } from "shared/src/util.mjs";

export async function loader({ params }) {
  const res = await api.loadLocationEditData({ id: params.id });
  if (res.data.location == null) {
    throw new Response("Not Found", { status: 404, statusText: "Not Found" });
  }
  return res;
}

export async function action(opts) {
  const data = await opts.request.json();

  return api.patchLocation(data);
}

function initialNewWorkingHourFromState() {
  return {
    valid_from: null,
    valid_to: null,
    from: null,
    to: null,
  };
}

function dateRangeConstraints(from, to) {
  const minFrom = new Date();
  const minTo = from ?? minFrom;
  const maxFrom = to;

  return {
    from: {
      min: f.dateInput(minFrom),
      max: f.dateInput(maxFrom),
    },
    to: {
      min: f.dateInput(minTo),
    },
  };
}

function timeRangeConstraints(from, to) {
  const minTo = from;
  const maxFrom = to;

  return {
    from: {
      max: maxFrom,
    },
    to: {
      min: minTo,
    },
  };
}

function useChangeStringSetter(setState, prop) {
  return React.useCallback(
    (e) => {
      setState((state) => ({
        ...state,
        [prop]:
          e.currentTarget.value.length === 0 ? null : e.currentTarget.value,
      }));
    },
    [setState, prop],
  );
}

function NewWorkingHourForm({ day, close }) {
  const { t } = useTranslation();
  const [state, setState] = React.useState(initialNewWorkingHourFromState);
  const validConstraints = dateRangeConstraints(
    state.valid_from,
    state.valid_to,
  );
  const timeConstraints = timeRangeConstraints(state.from, state.to);
  const onValidFromChange = useChangeStringSetter(setState, "valid_from");
  const onValidToChange = useChangeStringSetter(setState, "valid_to");
  const onFromChange = useChangeStringSetter(setState, "from");
  const onToChange = useChangeStringSetter(setState, "to");

  return (
    <>
      {day}
      <Box display="flex" gap="1rem">
        <TextField
          autoFocus
          label={t("working_hour.valid_from")}
          name={`working_hours.${day}.valid_from`}
          type="date"
          required
          slotProps={{
            inputLabel: { shrink: true },
            htmlInput: validConstraints.from,
          }}
          onChange={onValidFromChange}
        />
        <TextField
          label={t("working_hour.valid_to")}
          name={`working_hours.${day}.valid_to`}
          type="date"
          required
          slotProps={{
            inputLabel: { shrink: true },
            htmlInput: validConstraints.to,
          }}
          onChange={onValidToChange}
        />
        <TextField
          label={t("working_hour.from")}
          name={`working_hours.${day}.from`}
          type="time"
          required
          slotProps={{
            inputLabel: { shrink: true },
            htmlInput: timeConstraints.from,
          }}
          onChange={onFromChange}
        />
        <TextField
          label={t("working_hour.to")}
          name={`working_hours.${day}.to`}
          type="time"
          required
          slotProps={{
            inputLabel: { shrink: true },
            htmlInput: timeConstraints.to,
          }}
          onChange={onToChange}
        />
        <IconButton onClick={() => close()}>
          <CloseIcon />
        </IconButton>
      </Box>
    </>
  );
}

function AddWorkingHoursForm() {
  const { t } = useTranslation();
  const [updatingDays, setUpdatingDays] = React.useState([]);
  const dayOptions = workingDays
    .filter((day) => !updatingDays.includes(day))
    .map((day) => ({ id: day, name: day }));

  return (
    <Box display="flex" flexDirection="column" gap="1rem" marginTop="1rem">
      {updatingDays.map((day) => (
        <NewWorkingHourForm
          key={day}
          day={day}
          close={() => setUpdatingDays(updatingDays.filter((d) => day !== d))}
        />
      ))}
      {dayOptions.length > 0 && (
        <AutocompleteSelect
          label={t("calendars.addWorkingHours")}
          options={dayOptions}
          value={null}
          onChange={(option) => {
            if (option == null) {
              return;
            }

            setUpdatingDays([...updatingDays, option.id]);
          }}
        />
      )}
    </Box>
  );
}

function atStartOfDay(dateS) {
  return `${dateS}T00:00:00`;
}

function convertWorkingHour(wh) {
  return {
    ...wh,
    valid_from: atStartOfDay(wh.valid_from),
    valid_to: atStartOfDay(wh.valid_to),
  };
}

function formConverter(formData) {
  return updateInIfExists(formData, ["working_hours"], (working_hours) => {
    return Object.fromEntries(
      Object.entries(working_hours).map(([day, wh]) => [
        day,
        [convertWorkingHour(wh)],
      ]),
    );
  });
}

export default function LocationEdit() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const location = loaderData.data.location;
  const [open, setOpen] = React.useState(false);
  const navigation = useNavigation();
  const formKey = React.useMemo(() => crypto.randomUUID(), [loaderData]);
  const { errors: formErrors, onSubmit } = useRouterFormAction({
    converter: formConverter,
  });

  return (
    <>
      <Typography variant="h1"></Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              {t("calendars.day")}
            </TableCell>
            <TableCell>{t("calendars.workingHours")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workingDays.map((day) => {
            const workingHours = workingHoursInDay(
              new Date(),
              location.working_hours[day],
            );

            return (
              <React.Fragment key={day}>
                <TableRow sx={{ "& > td": { borderBottom: "unset" } }}>
                  <TableCell>{day}</TableCell>
                  <TableCell>
                    {workingHours &&
                      `${workingHours.from} - ${workingHours.to} (${f.date(workingHours.valid_from)} - ${f.date(workingHours.valid_to)})`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    sx={{ paddingBottom: 0, paddingTop: 0 }}
                  >
                    <Collapse in={open}>
                      <Box margin={1}>
                        <Table>
                          <TableBody>
                            {location.working_hours[day]?.map((wh, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  {wh.from} - {wh.to} ({f.date(wh.valid_from)} -{" "}
                                  {f.date(wh.valid_to)})
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
      <Form key={formKey} onSubmit={onSubmit}>
        <input name="id" type="hidden" value={location.id} />
        <AddWorkingHoursForm />
        <FormErrorList formErrors={formErrors} />
        <Button type="submit" disabled={navigation.state !== "idle"}>
          {navigation.state !== "idle" && <ButtonLoader />}
          {t("form.submit")}
        </Button>
      </Form>
    </>
  );
}
