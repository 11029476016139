import React from "react";
import * as api from "../services/api";
import { Form, useLoaderData, useNavigation } from "react-router-dom";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormErrorList, useRouterFormAction } from "../form";
import ButtonLoader from "../components/ButtonLoader";

export async function loader({ params }) {
  const res = await api.loadCalendarEditData({ id: params.id });
  if (res.data.calendar == null) {
    throw new Response("Not Found", { status: 404, statusText: "Not Found" });
  }
  return res;
}

export async function action(opts) {
  const data = await opts.request.json();

  return api.patchCalendar(data);
}

export default function CalendarEdit() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const calendar = loaderData.data.calendar;
  const { errors: formErrors, onSubmit } = useRouterFormAction();
  const formKey = React.useMemo(() => crypto.randomUUID(), [loaderData]);
  const navigation = useNavigation();

  return (
    <>
      <h1>
        {calendar.name} ({calendar.location?.name})
      </h1>
      <Form onSubmit={onSubmit} key={formKey}>
        <Box>
          <input type="hidden" name="id" value={calendar.id} />
          <FormControlLabel
            name="visible"
            control={<Checkbox defaultChecked={calendar.visible} />}
            label={t("calendars.visible")}
          />
          <FormErrorList formErrors={formErrors} />
        </Box>
        <Button type="submit" disabled={navigation.state !== "idle"}>
          {navigation.state !== "idle" && <ButtonLoader />}
          {t("form.submit")}
        </Button>
      </Form>
    </>
  );
}
