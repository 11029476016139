function validColorComponent(decValue) {
  return Math.min(255, Math.max(0, decValue));
}

/**
 * @param {string} hexColor
 *
 * @returns {string}
 */
export function complementColor(hexColor) {
  const hexRgb = [
    hexColor.substring(1, 3),
    hexColor.substring(3, 5),
    hexColor.substring(5, 7),
  ];
  const decRgb = hexRgb.map((hex) => parseInt(hex, 16));

  const sum = decRgb.reduce((prev, curr) => prev + curr, 0);
  const amount = sum >= 330 ? -26 : 25;

  return (
    "#" +
    decRgb
      .map((decColor) => validColorComponent(decColor + amount).toString(16))
      .join("")
  );
}

/**
 * @see {@link https://gist.github.com/jakearchibald/cb03f15670817001b1157e62a076fe95}
 *
 * @param {number} ms
 * @param {AbortSignal} signal
 * @param {Function} callback
 */
export function animationInterval(ms, signal, callback) {
  const start = document.timeline
    ? document.timeline.currentTime
    : performance.now();

  function frame(time) {
    if (signal.aborted) return;
    callback(time);
    scheduleFrame(time);
  }

  function scheduleFrame(time) {
    const elapsed = time - start;
    const roundedElapsed = Math.round(elapsed / ms) * ms;
    const targetNext = start + roundedElapsed + ms;
    const delay = targetNext - performance.now();
    setTimeout(() => requestAnimationFrame(frame), delay);
  }

  scheduleFrame(start);
}

export const saveIntendedUrl = (url) => {
  localStorage.setItem("intendedUrl", url);
};

export const getIntendedUrl = () => {
  return localStorage.getItem("intendedUrl");
};

export const clearIntendedUrl = () => {
  localStorage.removeItem("intendedUrl");
};
