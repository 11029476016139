import Holidays from "date-holidays";
import { isOverlap } from "./util.mjs";

const hd = new Holidays("CZ");

const days = {
  0: "su",
  1: "mo",
  2: "tu",
  3: "we",
  4: "th",
  5: "fr",
  6: "sa",
};

export const workingDays = ["mo", "tu", "we", "th", "fr"];

/**
 * @param {Date} date
 *
 * @returns {string}
 */
export function dateToDay(date) {
  return days[date.getDay()];
}

/**
 * @param {Date | string} day
 * @param {array | null} whs
 */
export function workingHoursInDayExcludingHolidays(day, whs) {
  if (whs == null) {
    return null;
  }

  const valid = whs.filter(
    (whs) => new Date(whs.valid_from) <= day && day <= new Date(whs.valid_to),
  );

  if (valid.length > 2) {
    console.error(
      `There is ${valid.length} valid working hours. Should be 1 or 0.`,
    );
  }

  return valid[0];
}

/**
 * @param {Date | string} day
 * @param {array | null} whs
 */
export function workingHoursInDay(day, whs) {
  if (hd.isHoliday(day)) {
    return null;
  }

  return workingHoursInDayExcludingHolidays(day, whs);
}

function mergeWith(f, m1, m2) {
  const res = { ...m1 };

  Object.entries(m2).reduce((acc, [k, v]) => {
    if (acc[k] == null) {
      acc[k] = v;
    } else {
      acc[k] = f(acc[k], v);
    }

    return res;
  }, res);

  return res;
}

function timeToNumber(time) {
  return parseInt(time.replace(":", ""), 10);
}

function numberToTime(x) {
  const s = String(x).padStart(4, "0");

  return `${s.substring(0, 2)}:${s.substring(2)}`;
}

function dateTimeToString(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -5);
}

function parseWorkingHour(wh, isNew = false) {
  return {
    valid_from: new Date(wh.valid_from),
    valid_to: new Date(wh.valid_to),
    from: timeToNumber(wh.from),
    to: timeToNumber(wh.to),
    new: isNew,
  };
}

function parseWorkingHours(whs, isNew = false) {
  return Object.fromEntries(
    Object.entries(whs).map(([day, whs]) => [
      day,
      whs.map((wh) => parseWorkingHour(wh, isNew)),
    ]),
  );
}

function convertWorkingHour(wh) {
  return {
    valid_from: dateTimeToString(wh.valid_from),
    valid_to: dateTimeToString(wh.valid_to),
    from: numberToTime(wh.from),
    to: numberToTime(wh.to),
  };
}

function convertWorkingHours(parsedWhs) {
  return Object.fromEntries(
    Object.entries(parsedWhs).map(([day, whs]) => [
      day,
      whs.map((wh) => convertWorkingHour(wh)),
    ]),
  );
}

function workingHourComparator(wh1, wh2) {
  const d1 = wh1.valid_from;
  const d2 = wh2.valid_from;

  return d1.getTime() - d2.getTime();
}

function removeOverlaps(sortedWh) {
  return sortedWh.reduce((acc, item) => {
    const prev = acc.at(-1);
    if (prev == null) {
      acc.push(item);
      return acc;
    }

    const overlaps = isOverlap(
      prev.valid_from,
      prev.valid_to,
      item.valid_from,
      item.valid_to,
    );
    if (!overlaps) {
      acc.push(item);
      return acc;
    }

    if (item.new) {
      const originalPrev = { ...prev };
      prev.valid_to = item.valid_from;
      if (prev.valid_from.getTime() === item.valid_from.getTime()) {
        acc.pop();
      }

      acc.push(item);
      if (item.valid_to < originalPrev.valid_to) {
        acc.push({
          ...originalPrev,
          valid_from: item.valid_to,
        });
      }
    } else {
      const next = { ...item, valid_from: prev.valid_to };
      acc.push(next);
    }

    return acc;
  }, []);
}

function mergeWorkingHours(whs1, whs2) {
  const whs = [...whs1, ...whs2].toSorted(workingHourComparator);

  return removeOverlaps(whs);
}

export function patchWorkingHours(existingWhs, newWhs) {
  const whs1 = parseWorkingHours(existingWhs);
  const whs2 = parseWorkingHours(newWhs, true);

  return convertWorkingHours(mergeWith(mergeWorkingHours, whs1, whs2));
}
