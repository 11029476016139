import React from "react";
import {
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import * as auth from "../auth";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import TodayIcon from "@mui/icons-material/Today";
import GroupsIcon from "@mui/icons-material/Groups";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import PersonIcon from "@mui/icons-material/Person";
import Link from "./Link";

export const drawerOpenWidth = 240;

const openedMixin = (theme) => ({
  width: drawerOpenWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  overflowX: "hidden",
});

const CustomDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerOpenWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

const Drawer = ({ open, handleDrawerOpen, handleDrawerClose }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;

  const menu = [
    {
      to: "/",
      icon: <DashboardIcon />,
      text: t("drawer.overview"),
    },
    {
      to: "/calendar",
      icon: <TodayIcon />,
      text: t("drawer.calendar"),
    },
    {
      to: "/fcalendar",
      icon: <TodayIcon />,
      text: t("drawer.fcalendar"),
    },
    {
      to: "/femployees",
      icon: <PersonIcon />,
      text: t("drawer.femployees"),
    },
    {
      to: "/calendars",
      icon: <EditCalendarIcon />,
      text: t("drawer.calendars"),
    },
    {
      to: "/clients",
      icon: <GroupsIcon />,
      text: t("drawer.clients"),
    },
    {
      to: "/reception",
      icon: <EventSeatIcon />,
      text: t("drawer.reception"),
    },
    {
      to: "/services",
      icon: <MedicalServicesIcon />,
      text: t("drawer.services"),
    },
    {
      to: "/locations",
      icon: <LocationCityIcon />,
      text: t("drawer.locations"),
    },
    {
      to: () => auth.logout(),
      text: t("drawer.logout"),
    },
  ];

  return (
    <CustomDrawer open={open} variant="permanent">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box p={1}>
          <img
            alt="app logo"
            src="/img/logo_placeholder.png"
            width="40"
            height="36"
          />
        </Box>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#app-bar-with-responsive-menu"
          sx={[
            {
              ml: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            },
            open
              ? {
                  display: "flex",
                }
              : {
                  display: "none",
                },
          ]}
        >
          NEW SMILE
        </Typography>
      </Box>
      <List>
        <ListItem
          sx={[
            open
              ? {
                  justifyContent: "end",
                }
              : {
                  justifyContent: "center",
                },
          ]}
        >
          <IconButton
            variant="contained"
            size="small"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            sx={{
              backgroundColor: "grey",
              color: "white",
              "&:hover": { color: "black" },
            }}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </ListItem>
        {menu.map((m) => {
          const btnProps =
            typeof m.to === "function"
              ? { onClick: m.to }
              : { component: Link, to: m.to };

          return (
            <ListItem key={m.text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                {...btnProps}
                selected={pathname === m.to}
                sx={
                  open
                    ? {
                        justifyContent: "initial",
                      }
                    : {
                        justifyContent: "center",
                      }
                }
              >
                {m.icon && (
                  <ListItemIcon
                    sx={[
                      {
                        minWidth: 0,
                        justifyContent: "center",
                      },
                      open
                        ? {
                            mr: 3,
                          }
                        : {
                            mr: "auto",
                          },
                    ]}
                  >
                    {m.icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={m.text}
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </CustomDrawer>
  );
};

export default Drawer;
