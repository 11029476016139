import { config } from "../config/config";
import { getAuthenticationToken } from "../auth";

const subscriptions = new Set();
let socket = null;
let running = false;

export function subscribe(f) {
  subscriptions.add(f);
}

export function unsubscribe(f) {
  subscriptions.delete(f);
}

function onMessage(msg) {
  subscriptions.forEach((f) => f(msg));
}

function reconnectWS() {
  console.log("reconnecting ws...");
  setTimeout(() => connect(), 1000);
}

function connect() {
  if (!running) {
    return;
  }

  console.log("Initializing WebSocket connection...");
  if (socket && socket.readyState === WebSocket.OPEN) {
    console.log("WebSocket is already open.");
    return;
  }

  const authToken = getAuthenticationToken();

  socket = new WebSocket(
    `${config.apiWs}?auth_token=${encodeURIComponent(authToken)}`,
  );

  socket.addEventListener("close", () => {
    console.log("WebSocket closed, attempting to reconnect...");
    reconnectWS();
  });

  socket.addEventListener("message", (event) => {
    console.log("WebSocket message received:", event.data);
    onMessage(JSON.parse(event.data));
  });
}

export function connectWS() {
  running = true;
  connect();
}

export function disconnectWS() {
  running = false;
  if (socket) {
    socket.close();
    socket = null;
    console.log("WebSocket connection closed.");
  }
}
